import localStorage from "local-storage";
import React, { useEffect } from 'react';
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from '../../actions/index';
import { LOGIN_PAGE } from "../lib/basic";
//import { SystemManagerUI } from '../admin/ManagerContainer/SystemManager'
export const NavBar = () => {

  const user = useSelector(state => state.authenticated);
  const dispatch = useDispatch();
  // const history = useHistory();

  useEffect(() => {
    if (user && window.location.href !== `${LOGIN_PAGE}`) {
      if (user.role === "admin" || user.role === "superAdmin") {
        if (!localStorage.get("timer")) {
          localStorage.set("timer", 0);
        }
        setInterval(async () => {
          if (localStorage.get("timer") === 3000) {
            await logout(user, dispatch);
            window.location.replace(LOGIN_PAGE);
          }
          localStorage.set("timer", localStorage.get("timer") + 1);
        }, 1000);
      }
    }
  }, []);

  const logOut = async () => {
    await logout(user, dispatch);
    window.location.replace(LOGIN_PAGE);
  }

  return (
    user ? <Navbar
      id="navbar"
      collapseOnSelect
      expand="lg"
      variant="dark"
      style={{ backgroundColor: "#001529" }}
    >
      <Navbar.Brand>
        <img
          alt=""
          src={process.env.PUBLIC_URL + "/img/logo.png"}
          style={{ height: "40px" }}
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link style={{ fontSize: "18px" }} href="/home">
            Home
        </Nav.Link>
          {/*<Nav.Link href="#features">Features</Nav.Link>*/}
          {user.role === "superAdmin" || user.role === "admin" || user.role === "businessRep" ?
            (<NavDropdown
              style={{ fontSize: "18px" }}
              title="TASK"
              id="collasible-nav-dropdown"
            >
              {   /* <NavDropdown.Item href="/task-shipment">
            SHIPMENT
          </NavDropdown.Item>*/}
              <NavDropdown.Item href="/task-manage-shipment">
                MANAGE SHIPMENT
          </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/task-skid">SKID</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/task-item">ITEM</NavDropdown.Item>

            </NavDropdown>
            ) : (
              <div></div>
            )}
          {(user.role === "operator" || user.role === "admin" || user.role === "superAdmin" || user.role === "businessRep") && (
            <Nav.Link style={{ fontSize: "18px" }} onClick={() => {
              window.open('/daily-target', '_blank');
            }}>
              Daily Target
            </Nav.Link>
          )}
          {user.role === "admin" || user.role === "superAdmin" || user.role === "businessRep" ?
            (<NavDropdown
              style={{ fontSize: "18px" }}
              title="Admin"
              id="admin-collasible-nav-dropdown"
            >
              {user.role === "admin" || user.role === "superAdmin" || user.role === "businessRep" ? (
                <div>
                  <NavDropdown.Item href="/admin-client">
                    Manage Client
                  </NavDropdown.Item>

                  <NavDropdown.Divider />
                </div>
              ) : (<div></div>)}
              { user.role === "superAdmin" ? (
                <div>
                  <NavDropdown.Item href="/admin-business-rep">
                    Manage Business Rep.
            </NavDropdown.Item>
                  <NavDropdown.Divider />
                </div>) : (<div></div>)}
              {user.role === "admin" || user.role === "superAdmin" ? (
                <div>
                  <NavDropdown.Item href="/admin-user">
                    Manage User
            </NavDropdown.Item>
                  <NavDropdown.Divider />
                </div>) : (<div></div>)}

              {user.role === "superAdmin" || user.role==="admin" ? (
                <div>
                  <NavDropdown.Item href="/manage-kpi">
                    Manage KPI
            </NavDropdown.Item>
                  <NavDropdown.Divider />
                </div>
              ) : (<div></div>)}
              <NavDropdown.Item href="/manager">Manager</NavDropdown.Item>
            </NavDropdown>
            
            ) 
            : (

              <div></div>
            )
          }
          {user.role === "superAdmin" || user.role === "admin" || user.role === "businessRep" ?
            (<NavDropdown
              style={{ fontSize: "18px" }}
              title="Reports"
              id="collasible-nav-dropdown"
            >
              {   /* <NavDropdown.Item href="/task-shipment">
            SHIPMENT
          </NavDropdown.Item>*/}
              <NavDropdown.Item href="/reports-monthly-production">
              Monthly production
          </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/reports-current-inventory">Current Inventory</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/reports-monthly-production-rates">Monthly production rates</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/reports-unitsreceived-deployed">Units Received vs Deployed per month</NavDropdown.Item>


            </NavDropdown>
            ) : (
              <div></div>
            )}
        </Nav>
        <Nav>
          <Nav.Link>
            Version 2.13.3
        </Nav.Link>
          <Nav.Link href="/me" style={{ color: "white" }}>
            Hi, {user.userName}
          </Nav.Link>
          <Nav.Link
            eventKey={2}
            onSelect={async () => logOut()}
          >
            Log out
        </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar> : <div></div>
  )
}
